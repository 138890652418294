<template>
  <div>
    <div>
      <b-card>
        <b-row>
          <b-col
            cols="12"
            lg="6 border-right"
          >
            <OrderDetails
              :order="order"
            />
            <ProductDetails
              :order="order"
            />
            <DeliveryDestination
              :order="order"
            />
            <DeliveryOrigin
              :order="order"
            />
            <SmartTruck
              :order="order"
            />
          </b-col>
          <b-col
            cols="12"
            lg="6"
          >
            <OrderLogs :order="order" />
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  OrderDetails, ProductDetails, DeliveryDestination, DeliveryOrigin, SmartTruck, OrderLogs,
} from '@/components'

export default {
  name: 'OrderTrackingDetails',
  components: {
    OrderDetails,
    ProductDetails,
    DeliveryDestination,
    DeliveryOrigin,
    SmartTruck,
    OrderLogs,
  },
  props: {
    order: {
      type: [Object, Array],
      required: true,
    },
  },
}
</script>
