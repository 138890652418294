<template>
  <div>
    <b-overlay :show="isLoading">
      <div v-if="delivery">
        <b-button
          variant="primary"
          class="mb-2"
          @click="$router.go(-1)"
        >
          <FeatherIcon icon="ArrowLeftIcon" />
          {{ $t('go_back') }}
        </b-button>
        <b-row>
          <b-col
            cols="12"
            lg="3"
          >
            <b-card v-if="delivery">
              <h3 class="mb-1">
                {{ $t('delivery_id') }}:
                <span class="font-weight-normal">{{ delivery.id }}</span>
              </h3>
              <h5>
                {{ $t('we_found') }} {{ Object.keys(delivery.orders).length }}
                <span v-if="Object.keys(delivery.orders).length > 1">{{ $t('orders') }}</span>
                <span v-else>{{ $t('order') }}</span>.
              </h5>
            </b-card>
            <b-card v-else>
              <h3 class="mb-1">
                {{ $t('delivery_id') }}:
                <span class="font-weight-normal">{{ delivery.id }}</span>
              </h3>
              <h5>
                {{ $t('no_orders_found') }}
              </h5>
            </b-card>
          </b-col>
        </b-row>

        <b-row v-if="delivery.orders">
          <b-col cols="12">
            <AppCollapse v-if="!isLoading">
              <AppCollapseItem
                v-for="(order, index) in delivery.orders"
                :key="index"
                :title="`${$t('order_id')}: ${order.reference_id}`"
              >
                <b-card>
                  <DeliveryProgress :order="order" />
                </b-card>
                <OrderTrackingDetails :order="order" />
              </AppCollapseItem>
            </AppCollapse>
            <div v-else>
              <b-row>
                <b-col cols="12">
                  <b-card class="text-center">
                    <h6>{{ $t('we_are_fetching_data') }}</h6>
                  </b-card>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </div>

      <div v-else>
        <b-row>
          <b-col>
            <b-card class="text-center">
              <h6>Sorry. No data found.{{ $t('sorry_no_data_found') }}</h6>
            </b-card>
          </b-col>
        </b-row>
      </div>

    </b-overlay>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { heightFade } from '@core/directives/animations'
import OrderTrackingDetails from '@/components/OrderTrackingDetails.vue'
import DeliveryProgress from '@/components/DeliveryTracking/DeliveryProgress.vue'

export default {
  name: 'TrackOrder',
  components: {
    AppCollapse,
    AppCollapseItem,
    OrderTrackingDetails,
    DeliveryProgress,
  },
  directives: {
    'height-fade': heightFade,
  },
  data() {
    return {
      delivery: null,
      isLoading: false,
    }
  },
  created() {
    this.getOrders()
  },
  methods: {
    async getOrders() {
      try {
        this.isLoading = true
        const response = await this.$http.get(`deliveries/${this.$route.params.deliveryId}`)
        this.delivery = response.data
        this.isLoading = false
      } catch (e) {
        console.error(e)
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-faq.scss';
</style>
